.menu-item-price-page {
  .label-as::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .ant-table-row .draggable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    font-size: 1.2rem;
    transform: rotate(90deg);
  }

  .gu-mirror {
    background-color: rgba(16, 142, 233, 0.15);
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .ant-table-tbody .ant-table-row {
    cursor: grab;
  }

  .ant-table-tbody .ant-table-row:active {
    cursor: grabbing;
  }

  /* Container styling for responsiveness and scrollable table */
  .table-container {
    width: 100%;
    max-height: 60vh; /* Set max-height for scrolling */
    overflow: auto; /* Enable vertical scroll */
    margin: 0 auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }

  thead th {
    background-color: #f5f5f5;
    padding: 10px;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #eaeaea;
  }

  thead th svg {
    margin-left: 5px;
  }

  tbody td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eaeaea;
  }

  .no-data {
    height: 60px;
  }

  .empty-state {
    text-align: center;
    padding: 50px;
  }

  .empty-state img {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto 10px;
  }

  .empty-state p {
    font-size: 14px;
    color: #999999;
    margin: 0;
  }
}
