// @import "./theme/colors.scss";
@import "../../../theme/colors.scss";

.ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 150px; /* Adjust based on your requirement */
}

.add {
  cursor: pointer;
  background: $primary-color !important;
  color: $light-color !important;
}

// .table-container {
//   height: 50vh;
//   // overflow-y: auto; /* Enable vertical scrolling */
// }

.ant-table-expanded-row-fixed {
  // height: 100vh !important;
  min-height: 20vh !important;
  max-height: 100vh !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 9px 9px !important;
}

/* Target the first row in the tbody specifically */
// .ant-table-wrapper .ant-table-tbody > tr:first-child > td {
//   padding: 0px !important;
// }
/* Apply consistent padding to all table cells */
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 9px !important;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 9px !important;
}
// .ant-table-wrapper .ant-table-tbody > tr:first-child > td {
//   padding-top: 0px !important; /* Adjust padding as needed */
// }

.modified-row {
  background: #fcd9b8;
}
