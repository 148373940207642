.label-as::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.menu-record {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.ant-table-row .draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  font-size: 1.2rem;
  transform: rotate(90deg);
}

.gu-mirror {
  background-color: rgba(16, 142, 233, 0.15);
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
