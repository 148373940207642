@import "./theme/colors.scss";

// Scrollbar Mixins
@mixin scrollbar-base {
  width: 5px;
  height: 5px !important;
}

@mixin scrollbar-track {
  background: $scrollbar-track;
  border-radius: 10px;
}

@mixin scrollbar-thumb {
  background: $scrollbar-thumb;
  border-radius: 10px;

  &:hover {
    background: $scrollbar-thumb-hover;
  }
}

// Base styles
body {
  padding: 0;
  margin: 0;
}

// Webkit Scrollbar styles
::-webkit-scrollbar {
  @include scrollbar-base;
}

::-webkit-scrollbar-track {
  @include scrollbar-track;
}

::-webkit-scrollbar-thumb {
  @include scrollbar-thumb;
}

// Firefox Scrollbar styles
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb $scrollbar-track;
}

// Ant Design overrides
.ant-modal-confirm {
  .ant-modal-confirm-btns {
    .ant-btn + .ant-btn {
      background: $primary-color;

      .ant-typography {
        color: $light-color;
      }
    }
  }
}

.ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ant-menu {
  &-item-icon {
    height: 34px;
    width: 25px;
  }

  &-item-selected {
    background-color: $primary-color !important;
    color: #fff;
  }

  &-dark .ant-menu-submenu-selected > .ant-menu-submenu-title {
    border: 1px solid #22ab53;
  }
}

.ant-layout .ant-layout-sider {
  overflow-y: auto !important;
}

.ant-table {
  &-body {
    overflow: auto !important;
    height: 50vh !important;
    min-height: 50vh !important;
    max-height: 50vh !important;
  }

  &-wrapper {
    .ant-table-cell-fix-right-first::after {
      width: 100px !important;
    }

    .ant-table-cell-fix-right {
      right: -9px !important;
    }
  }
}

.items .ant-modal .ant-modal-content {
  width: 50vw;
}
